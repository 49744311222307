//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { sysNoticePage, sysNoticeDelete, sysNoticeChangeStatus } from '@/api/modular/system/noticeManage'
import addForm from './addForm'
import editForm from './editForm'
import detailForm from './detailForm'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm,
    detailForm
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '標題',
          dataIndex: 'title'
        },
        {
          title: '類型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return sysNoticePage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusDictTypeDropDown: [], // 0草稿 1發布 2撤回 3刪除
      typeDictTypeDropDown: []// 0通知 1公告
    }
  },
  created () {
    this.sysDictTypeDropDown()// 先註釋
    if (this.hasPerm('sysNotice:changeStatus') || this.hasPerm('sysNotice:edit') || this.hasPerm('sysNotice:delete')) {
      this.columns.push({
        title: '操作',
        width: '300px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      this.statusDictTypeDropDown = this.$options.filters['dictData']('notice_status')
      this.typeDictTypeDropDown = this.$options.filters['dictData']('notice_type')
    },
    /**
     * 修改狀態
     */
    editNoticeStatus (code, record) {
      sysNoticeChangeStatus({ id: record.id, status: code.toString() }).then(res => {
        if (res.success) {
          this.$message.success('操作成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('操作失敗：' + res.message)
        }
      })
    },
    /**
     * 提交
     */
    sysNoticeDelete (record) {
      sysNoticeDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
