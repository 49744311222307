//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { sysNoticeAdd } from '@/api/modular/system/noticeManage'
import { sysUserSelector } from '@/api/modular/system/userManage'
import { AntdEditor } from '@/components'
export default {
  name: 'AddForm',
  components: {
    AntdEditor,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      editorContent: '',
      editorContentText: '',
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      mockData: [],
      targetKeys: [],
      typeDictTypeDropDown: [], // 0通知 1公告
      formLoading: true,
    }
  },
  methods: {
    // 初始化方法
    add() {
      this.visible = true
      this.sysDictTypeDropDown() // 先註釋
      this.getMock()
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown() {
      sysDictTypeDropDown({ code: 'notice_type' }).then((res) => {
        this.typeDictTypeDropDown = res.data
      })
    },
    /**
     * 編輯器回調上傳及回傳圖片url
     */
    editorUploadImage(files, insert) {
      const formData = new FormData()
      files.forEach((file) => {
        formData.append('file', file)
      })
      sysFileInfoUpload(formData)
        .then((res) => {
          if (res.success) {
            insert(process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + res.data)
          } else {
            this.$message.error('編輯器上傳圖片失敗：' + res.message)
          }
        })
        .catch((err) => {
          this.$message.error('預覽錯誤：' + err.message)
        })
    },
    getEditor(editor) {
      this.editor = editor
    },
    changeEditor(html, ele) {
      console.log(1111)
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    /**
     * 穿梭框
     */
    getMock() {
      const targetKeys = []
      const mockData = []
      sysUserSelector().then((res) => {
        this.formLoading = false
        for (let i = 0; i < res.data.length; i++) {
          const data = {
            key: res.data[i].id.toString(),
            title: res.data[i].name,
            description: `description of ${res.data[i].name}`,
          }
          mockData.push(data)
        }
      })
      this.mockData = mockData
      this.targetKeys = targetKeys
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    handleSubmit(types) {
      const {
        form: { validateFields },
      } = this
      // eslint-disable-next-line eqeqeq
      if (this.editorContent == '') {
        this.$message.error('請填寫內容')
        return
      }
      if (this.targetKeys.length < 1) {
        this.$message.error('請選擇通知到的人')
        return
      }
      validateFields((errors, values) => {
        if (!errors) {
          this.formLoading = true
          values.content = this.editorContent
          values.status = types
          values.noticeUserIdList = this.targetKeys
          sysNoticeAdd(values)
            .then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$emit('ok', values)
                this.handleCancel()
              } else {
                this.$message.error('新增失敗：' + res.message)
              }
            })
            .finally((res) => {
              this.formLoading = false
            })
        }
      })
    },
    handleCancel() {
      this.editor.txt.clear()
      this.targetKeys = []
      this.editorContent = ''
      this.form.resetFields()
      this.visible = false
      this.formLoading = true
    },
  },
}
