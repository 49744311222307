//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { sysNoticeDetail, sysNoticeEdit } from '@/api/modular/system/noticeManage'
import { sysUserSelector } from '@/api/modular/system/userManage'
import { AntdEditor } from '@/components'
export default {
    name: 'AddForm',
    components: {
        AntdEditor,
    },
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            visible: false,
            form: this.$form.createForm(this),
            typeDictTypeDropDown: [], // 0通知 1公告
            editorContent: '',
            editorContentText: '',
            editorUploadConfig: {
                method: 'http',
                callback: this.editorUploadImage,
            },
            mockData: [],
            targetKeys: [],
            noticeDetail: [],
            formLoading: true,
        }
    },
    methods: {
        // 初始化方法
        edit(record) {
            this.visible = true
            this.sysNoticeDetail(record.id)
            this.sysDictTypeDropDown()
            setTimeout(() => {
                this.form.setFieldsValue({
                    id: record.id,
                    title: record.title,
                    type: record.type.toString(),
                })
                this.editor.txt.html(record.content)
                this.editorContent = record.content
            }, 100)
        },
        /**
         * 獲取字典數據
         */
        sysDictTypeDropDown() {
            sysDictTypeDropDown({ code: 'notice_type' }).then((res) => {
                this.typeDictTypeDropDown = res.data
            })
        },
        /**
         * 編輯器回調上傳及回傳圖片url
         */
        editorUploadImage(files, insert) {
            const formData = new FormData()
            files.forEach((file) => {
                formData.append('file', file)
            })
            sysFileInfoUpload(formData).then((res) => {
                if (res.success) {
                    insert(process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + res.data)
                } else {
                    this.$message.error('編輯器上傳圖片失敗：' + res.message)
                }
            })
        },
        getEditor(editor) {
            this.editor = editor
        },
        changeEditor(html, ele) {
            this.editorContent = html
            this.editorContentText = ele.text()
        },
        /**
         * 編輯時獲取全部信息
         */
        sysNoticeDetail(id) {
            sysNoticeDetail({ id: id }).then((res) => {
                this.noticeDetail = res.data
                this.getMock(this.noticeDetail)
            })
        },
        /**
         * 穿梭框
         */
        getMock(noticeDetail) {
            const targetKeys = []
            const mockData = []
            sysUserSelector().then((res) => {
                this.formLoading = false
                for (let i = 0; i < res.data.length; i++) {
                    const data = {
                        key: res.data[i].id.toString(),
                        title: res.data[i].name,
                        description: `description of ${res.data[i].name}`,
                    }
                    for (let j = 0; j < noticeDetail.noticeUserIdList.length; j++) {
                        if (data.key === noticeDetail.noticeUserIdList[j]) {
                            targetKeys.push(noticeDetail.noticeUserIdList[j])
                        }
                    }
                    mockData.push(data)
                }
            })
            this.mockData = mockData
            this.targetKeys = targetKeys
        },
        filterOption(inputValue, option) {
            return option.description.indexOf(inputValue) > -1
        },
        handleChange(targetKeys, direction, moveKeys) {
            this.targetKeys = targetKeys
        },
        handleSubmit(types) {
            const {
                form: { validateFields },
            } = this
            // eslint-disable-next-line eqeqeq
            if (this.editorContent == '') {
                this.$message.error('請填寫內容')
                return
            }
            if (this.targetKeys.length < 1) {
                this.$message.error('請選擇通知到的人')
                return
            }
            validateFields((errors, values) => {
                if (!errors) {
                    this.formLoading = true
                    values.content = this.editorContent
                    values.status = types
                    values.noticeUserIdList = this.targetKeys
                    sysNoticeEdit(values)
                        .then((res) => {
                            if (res.success) {
                                this.$message.success('編輯成功')
                                this.visible = false
                                this.$emit('ok', values)
                                this.handleCancel()
                            } else {
                                this.$message.error('編輯失敗：' + res.message)
                            }
                        })
                        .finally((res) => {
                            this.formLoading = false
                        })
                }
            })
        },
        handleCancel() {
            this.editor.txt.clear()
            this.targetKeys = []
            this.editorContent = ''
            this.form.resetFields()
            this.visible = false
            this.formLoading = true
        },
    },
}
